<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb :items="breadcrumbItems" />
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_02.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O acabamento das casas mais antigas é feito com caiação das
                  paredes. Mesmo com a chegada das tintas sintéticas no mercado
                  e o acesso a esse material pelos moradores das comunidades de
                  Mariana, muitas casas e edificações de uso rural continuaram a
                  receber essa pintura à base de cal. Trata-se de uma técnica de
                  menor custo, de fácil aplicação e, se feita duas vezes no ano,
                  mantém a casa limpa e desinfetada. Além do branco, geralmente
                  são encontradas paredes na cor rosa, amarelo claro, azul e
                  verde.
                </p>
                <p>
                  A tinta para caiação é preparada com cal hidratada misturada à
                  água, sendo adicionados produtos para fixação nas paredes ou
                  muros, podendo ser acrescentados pigmentos corantes quando
                  desejado. As demãos devem ser aplicadas em camadas finas para
                  que haja uma boa adesão à superfície quando secar.
                </p>
                <p>
                  Também era comum ser utilizada a técnica do barreado nas casas
                  das comunidades de Mariana, havendo ainda exemplares nas
                  ruínas de Paracatu de Baixo, nas edificações de Campinas e
                  Pedras. O barreado se vale da aplicação de terra sobre paredes
                  executadas com técnicas que utilizam o solo (pau a pique e
                  adobe), com um pano. Não usados solos argilosos, geralmente
                  tabatinga, diluídos em água. Como a matéria-prima das paredes
                  é a mesma do acabamento, o resultado é de boa durabilidade, já
                  que existe compatibilidade entre os materiais. Além do
                  barreado ser usado como pintura, há também casos em que a
                  técnica é adotada no reboco.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_15.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da fachada frontal com pintura à base de cal de uma casa da
            comunidade de Pedras. Foto: Vistoria da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_16.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Em segundo plano, pintura à base de cal com pigmento na cor amarela
            e, em segundo plano, caiação branca. Foto: Vistoria da Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A depender do solo onde a terra é retirada, o resultado final
                  possibilita paredes de diversas cores como amarelo, branco
                  acinzentado, já que nos terrenos, o chão possui cores
                  diferentes. O marrom, o vermelho e o amarelo demonstram a
                  presença de óxido de ferro; as cores mais escuras, de matéria
                  orgânica, e os solos esbranquiçados das baixadas (batinga ou
                  tabatinga), ausência ou quantidade mínima de ferro. Esse
                  acabamento era feito além de paredes, em fogões a lenha e
                  fornos.
                </p>
                <p>
                  Os elementos construtivos de madeira ou de ferro recebem o
                  acabamento com tinta esmalte, sendo que nas mais antigas
                  também são encontradas pinturas com tinta a óleo. As portas e
                  janelas recebem tons mais escuros como marrons, vermelho
                  queimado, azul, verde e ocre. Já nos forros são usados os tons
                  mais claros, da mesma forma que nas guilhotinas das janelas ou
                  nas bandeiras de vidro. Os esteios (pilares) e vigas também
                  recebem o acabamento com a tinta esmalte ou a óleo, na mesma
                  cor utilizada nos enquadramentos dos vãos de portas e janelas.
                  Se for da opção do morador, as folhas recebem uma outra cor
                  também em tonalidade escura.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_17.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Parede de uma casa em Paracatu de Baixo com pintura de barreado
            sobre os tijolos de adobe. Foto: Vistoria da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_18.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casarão situado em terreno rural de Paracatu de Baixo, com pintura à
            base de cal nas alvenarias construídas com técnicas utilizando
            barro. As portas e janelas, bem como a estrutura de madeira
            receberam pintura com tinta esmalte. Foto: Vistoria técnica da
            Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_paredes' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Paredes"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa",
          href: "conteudos_tematicos_casas",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }

  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image3::before {
    display: none;
  }

  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
